<template>
  <div>
    <div class="head-name-title" style="margin-left: 20px">农资管理</div>
    <div style="margin:20px 0 30px 20px ">
      <el-button type="primary" @click="hideDialog" v-if="this.$getPermission('/pc/farmingCapital/save')">添加农资
      </el-button>
      <el-dialog
          title=""
          :visible.sync="dialogVisible"
          width="600px"
      >
        <div style="height: 412px;width: 400px;text-align: center;margin-left: 80px">
          <div class="dialog-title">{{ buttonText }}农资</div>
          <el-form label-width="100px">


            <el-form-item label="农资名称">
              <el-input v-model="dataForm.name"></el-input>
            </el-form-item>
            <el-form-item label="单位">
              <el-select v-model="dataForm.unit" placeholder="请选择" style="width: 300px;">
                <el-option
                    v-for="item in unitList"
                    :key="item.name"
                    :label="item.name"
                    :value="item.name"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="所属品类">
              <el-select v-model="dataForm.typeId " placeholder="请选择" style="width: 300px;">
                <el-option
                    v-for="item in farmingCapitalList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-radio-group v-model="dataForm.isExpend">
              <el-radio :label="1">消耗品</el-radio>
              <el-radio :label="0">非消耗品</el-radio>
            </el-radio-group>
          </el-form>
          <el-col>
            <el-button class="dialog-button" @click="update">提交</el-button>
          </el-col>
        </div>
      </el-dialog>
    </div>
    <div>
      <el-table
          :data="tableData"
          border
          style="width: 918px;margin-left: 36px;margin-bottom: 30px"
          :row-class-name="tableRowClassName">
        <el-table-column
            prop="id"
            label="序号"
        >
        </el-table-column>
        <el-table-column
            prop="name"
            label="名称"
        >
        </el-table-column>
        <el-table-column
            prop="typeName"
            label="品类">
        </el-table-column>
        <el-table-column
            prop="unit"
            label="单位">
        </el-table-column>
        <el-table-column
            prop="isExpend"
            :formatter="changeTypeClass"
            label="类型">
        </el-table-column>
        <el-table-column
            label="操作">
          <template slot-scope="scope">
            <el-button
                size="mini"
                @click="handleEdit(scope.$index, scope.row)">编辑
            </el-button>
            <el-popconfirm style="margin-left: 10px"
                           title="确定删除吗？"
                           @confirm="handleDelete(scope.$index, scope.row)"
            >
              <el-button
                  size="mini"
                  type="danger"
                  slot="reference"
              >删除
              </el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          style="margin-bottom: 20px;margin-left: 30px"
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="pageIndex"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          :total="totalPage"
          layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>

  </div>
</template>

<script>
export default {
  inject: [
    'reload'
  ],
  data() {
    return {
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      buttonText: '添加',
      dataForm: {
        name: '',
        typeId: '',
        unit: '',
        isExpend: '',
      },
      id: '',
      value1: new Date(2016, 9, 10, 18, 40),
      dialogVisible: false,
      tableData: [],
      changeList: [],
      unitList: [],
      farmingCapitalList: []
    }
  },
  mounted() {
    this.getDataList()
    localStorage.setItem('selectIndex', '3')
    this.$api.post('farmingCapitalType/select',  //品类列表
        {}, res => {
          console.log(res, '123')
          this.farmingCapitalList = res.data
        });
    this.$api.post('unit/select',  //品类列表
        {}, res => {
          console.log(res, '123')
          this.unitList = res.data
        });
  },
  methods: {
    hideDialog() {
      this.dialogVisible = true
      this.buttonText = '添加'
      this.dataForm.name = ''
      this.dataForm.typeId = ''
      this.dataForm.unit = ''
      this.dataForm.isExpend = ''
    },
    changeTypeClass(row) {
      return row.isExpend == '0' ? "非消耗品" : row.isExpend == '1' ? "消耗品" : "";
    },
    getDataList() {
      this.$api.post('farmingCapital/getList', {
        "page": this.pageIndex,
        "size": this.pageSize,
      }, res => {
        console.log(res, '123')
        this.tableData = res.data.records
        this.totalPage = res.data.total
      });
    },
    tableRowClassName({rowIndex}) {
      if (rowIndex % 2 == 1) {
        return 'warning-row';
      } else if (rowIndex === 3) {
        return 'success-row';
      }
      return '';
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },

    handleEdit(index, row) {  // 修改按钮
      this.changeList = []
      this.buttonText = '修改'
      console.log(index, row.id);
      if (this.$getPermission('/pc/farmingCapital/update')) {
        this.dialogVisible = true
        let idx = this.tableData.findIndex((item) => item.id === row.id)
        console.log(idx, 'idx')
        this.changeList = this.tableData[idx]
        this.dataForm.name = this.tableData[idx].name
        this.dataForm.typeId = this.tableData[idx].typeId
        this.dataForm.unit = this.tableData[idx].unit
        this.dataForm.isExpend = this.tableData[idx].isExpend
        this.id = this.tableData[idx].id
        console.log(this.changeList, 'this.changeList')
      } else {
        this.$message({
          showClose: true,
          message: '暂无权限',
          type: 'warning'
        });
      }
    },
    handleDelete(index, row) {  //删除按钮
      console.log(index, row);
      if (this.$getPermission('/pc/farmingCapital/delById')) {
        this.$api.post('farmingCapital/delById', {id: row.id}, res => {
          console.log(res, '删除', this.tableData)
          let idx = this.tableData.findIndex((item) => item.id === row.id)
          console.log(idx)
          this.tableData.splice(idx, 1)
          this.$message({
            showClose: true,
            message: res.msg,
            type: 'success'
          });
        })
      } else {
        this.$message({
          showClose: true,
          message: '暂无权限',
          type: 'warning'
        });
      }
    },
    addtabList(data) {  //子组件静默添加列表数据
      console.log(data)
      this.tableData.push(data)
      this.dialogVisible = false
    },
    // 添加或修改
    update() {
      if (this.id !== '') {  //修改
        this.dataForm['id'] = this.id
        this.$api.post('farmingCapital/update', this.dataForm, res => {
          console.log(res, 'wwww')
          if (res.code === 0) {
            console.log('修改')
            this.reload()
          }
        })
      } else {   //添加
        this.$api.post('farmingCapital/save', this.dataForm, res => {
          console.log(res, 'wwww')
          if (res.code === 0) {
            console.log('添加成功')
            this.reload()
          }
        })
      }

    }
  }
}
</script>

<style scoped>
.head-name-title {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  height: 57px;
  line-height: 57px;
  border-bottom: 2px solid #E8E8E8;
}

.table-title-name {
  font-size: 20px;
  font-weight: 400;
  color: #333333;
  margin: 20px 0 5px 36px;

}

.dialog-title {
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  margin-bottom: 30px;
}

.dialog-button {
  height: 60px;
  background: #009400;
  border-radius: 30px;
  width: 400px;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FEFEFE;
  margin-top: 30px;
}
</style>
