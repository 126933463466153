<template>
  <div>
    <div class="body-box">
      <div style="width: 200px;float:left;">
        <el-menu
            :default-active="selectName"
            class="el-menu-vertical-demo"
            style="text-align: center"
            @select="handleOpen">
          <el-menu-item index="1"  v-if="this.$getPermission('/pc/base/getBase')">
            <span slot="title">基地信息</span>
          </el-menu-item>
          <el-menu-item index="2"  v-if="this.$getPermission('/pc/farmingCapitalType/getList')">
            <span slot="title">农资品类管理</span>
          </el-menu-item>
          <el-menu-item index="3"  v-if="this.$getPermission('/pc/farmingCapital/getList')">
            <span slot="title">农资管理</span>
          </el-menu-item>
          <el-menu-item index="4"  v-if="this.$getPermission('/pc/sign/getList')">
            <span slot="title">签到配置</span>
          </el-menu-item>


        </el-menu>
      </div>
      <div class="agricul_right ib">
        <div v-if="selectName == 1">
          <baseMessage></baseMessage>
        </div>
        <div v-if="selectName == 2">
          <classify></classify>
        </div>
        <div v-if="selectName == 3">
          <farmpeoducts></farmpeoducts>
        </div>
        <div v-if="selectName == 4">
          <signIn></signIn>
        </div>
        <div class="clear"></div>
      </div>
    </div>
  </div>
</template>

<script>
import baseMessage from '@/views/user/baseMessage'
import signIn from '@/components/other/signIn'
import farmpeoducts from '@/components/other/farmpeoducts'
import classify from '@/components/other/classify'

export default {
  components: {
    signIn,
    farmpeoducts,
    baseMessage,
    classify
  },
  data() {
    return {
      selectName:localStorage.getItem('selectIndex'),
    }
  },

  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
      this.selectName = key
    },
    tableRowClassName({rowIndex}) {
      if (rowIndex % 2 == 1) {
        return 'warning-row';
      } else if (rowIndex === 3) {
        return 'success-row';
      }
      return '';
    },
  }
}
</script>

<style scoped>
.body-box {
  margin-top: 20px;
}

.ib {
  /*display: inline-block;*/
}

.agricul_right {
  margin-left: 10px;
  width: 990px;

  background: #FFFFFF;
  float: left;
}

.clear {
  clear: both
}

.head-name-title {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  height: 57px;
  line-height: 57px;
  border-bottom: 2px solid #E8E8E8;
}
</style>
