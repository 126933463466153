<template>
  <div>
    <div class="head-name-title" style="margin-left: 20px">签到配置</div>
    <div style="margin:20px 0 30px 20px ">
      <el-button type="primary" @click="hideDialog" v-if="this.$getPermission('/pc/sign/save')">新增规则</el-button>
      <el-dialog
          title=""
          :visible.sync="dialogVisible"
          width="600px"
      >
        <div style="min-height: 800px;width: 400px;text-align: center;margin-left: 80px;padding-bottom: 100px">
          <div class="dialog-title">添加规则</div>
          <el-form label-width="100px">
            <el-form-item label="名称">
              <el-input v-model="dataForm.name"></el-input>
            </el-form-item>
            <el-form-item label="周期">
              <el-select v-model="workDays" multiple collapse-tags placeholder="请选择" style="width: 300px;">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.label"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="上班时间">
              <el-time-picker style="width: 300px;"
                              v-model="dataForm.startTime"
                              format='HH:mm' value-format='HH:mm'
                              placeholder="任意时间点">
              </el-time-picker>
            </el-form-item>
            <el-form-item label="下班时间">
              <el-time-picker style="width: 300px;"
                              v-model="dataForm.endTime"
                              format='HH:mm' value-format='HH:mm'
                              placeholder="任意时间点">
              </el-time-picker>
            </el-form-item>
            <el-form-item label="签到地点">
              <!--              <el-input v-model="dataForm.name"></el-input>-->
              <amap :default-value="dataForm.detailedAddress" @getPosition="getPosition"/>
            </el-form-item>
            <el-form-item label="范围/米">
              <el-input v-model="dataForm.scope  "></el-input>
            </el-form-item>
            <el-form-item label="适合角色">
              <el-select v-model="roleIds" multiple placeholder="请选择" style="width: 300px;"
              >
                <el-option
                    v-for="item in optionList"
                    :key="item.roleId"
                    :label="item.roleName"
                    :value="item.roleId"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <el-col>
            <el-button class="dialog-button" @click="update">提交</el-button>
          </el-col>
        </div>
      </el-dialog>
    </div>
    <div>
      <el-table
          :data="tableData"
          border
          style="width: 918px;margin-left: 36px;margin-bottom: 30px"
          :row-class-name="tableRowClassName">
        <el-table-column
            prop="id"
            label="序号"
        >
        </el-table-column>
        <el-table-column
            prop="name"
            label="名称"
        >
        </el-table-column>
        <el-table-column
            prop="workDays"
            label="周期"
        >
        </el-table-column>
        <el-table-column
            prop="startTime"
            label="上班时间">
        </el-table-column>
        <el-table-column
            prop="endTime"
            label="下班时间">
        </el-table-column>
        <el-table-column
            prop="detailedAddress"
            label="签到地点">
        </el-table-column>
        <el-table-column
            prop="scope"
            label="签到距离">
        </el-table-column>

        <el-table-column
            prop="roleNames"
            label="使用角色">
        </el-table-column>
        <el-table-column

            label="操作">
          <template slot-scope="scope">
            <el-popconfirm style="margin-left: 10px"
                           title="确定删除吗？"
                           @confirm="handleDelete(scope.$index, scope.row)"
            >
              <el-button
                  size="mini"
                  type="danger"
                  slot="reference"
              >删除
              </el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          style="margin-bottom: 20px;margin-left: 30px"
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="pageIndex"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          :total="totalPage"
          layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>

  </div>
</template>

<script>
import Amap from '@/components/map'

export default {
  components: {
    Amap
  },
  inject: [
    'reload'
  ],
  data() {
    return {
      postForm: {
        detailedAddress: '',
        longitude: '',
        latitude: ''
      },
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      options: [{
        value: 1,
        label: '星期天'
      }, {
        value: 2,
        label: '星期一'
      }, {
        value: 3,
        label: '星期二'
      }, {
        value: 4,
        label: '星期三'
      }, {
        value: 5,
        label: '星期四'
      }, {
        value: 6,
        label: '星期五'
      }, {
        value: 7,
        label: '星期六'
      }],
      dataForm: {
        detailedAddress:'',
        "endTime": "",
        "latitude": '',
        "longitude": '',
        "name": "",
        "scope": '',
        "startTime": "",
      },
      id: '',
      roleIds: [],
      "workDays": "",
      dialogVisible: false,
      tableData: [],
      changeList: [],
      optionList: [],
      unitList: [],
      farmingCapitalList: []
    }
  },
  mounted() {
    this.getDataList()
    localStorage.setItem('selectIndex', '4')
    this.$api.post('role/select',  //角色列表
        {}, res => {
          console.log(res, '123')
          this.optionList = res.data
        });
  },
  methods: {
    hideDialog() {
      this.dialogVisible = true
      this.workDays = []
      this.dataForm.startTime = ''
      this.dataForm.endTime = ''
      this.dataForm.detailedAddress = ''
      this.dataForm.scope = ''
      this.roleIds = []

    },
    // 获取地址信息
    getPosition({address, lat, lng}) {
      this.dataForm.detailedAddress = address;
      this.dataForm.longitude = String(lng);
      this.dataForm.latitude = String(lat);
    },
    changeTypeClass(row) {
      return row.isExpend == '0' ? "非消耗品" : row.isExpend == '1' ? "消耗品" : "";
    },
    getDataList() {
      this.$api.post('sign/getList', {
        "page": this.pageIndex,
        "size": this.pageSize,
      }, res => {
        console.log(res, '123')
        this.tableData = res.data.records
        this.totalPage = res.data.total
      });
    },
    tableRowClassName({rowIndex}) {
      if (rowIndex % 2 == 1) {
        return 'warning-row';
      } else if (rowIndex === 3) {
        return 'success-row';
      }
      return '';
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },

    // handleEdit(index, row) {  // 修改按钮
    //   this.changeList = []
    //   console.log(index, row.id);
    //   this.dialogVisible = true
    //   let idx = this.tableData.findIndex((item) => item.id === row.id)
    //   console.log(idx, 'idx')
    //   this.changeList = this.tableData[idx]
    //   this.dataForm.name = this.tableData[idx].name
    //   this.dataForm.typeId = this.tableData[idx].typeId
    //   this.dataForm.unit = this.tableData[idx].unit
    //   this.dataForm.isExpend = this.tableData[idx].isExpend
    //   this.id = this.tableData[idx].id
    //   console.log(this.changeList, 'this.changeList')
    // },
    handleDelete(index, row) {  //删除按钮
      console.log(index, row);
      if (this.$getPermission('/pc/sign/delById')) {
        this.$api.post('sign/delById', {id: row.id}, res => {
          console.log(res, '删除', this.tableData)
          let idx = this.tableData.findIndex((item) => item.id === row.id)
          console.log(idx)
          this.tableData.splice(idx, 1)
          this.$message({
            showClose: true,
            message: res.msg,
            type: 'success'
          });
        })
      } else {
        this.$message({
          showClose: true,
          message: '暂无权限',
          type: 'warning'
        });
      }

    },
    addtabList(data) {  //子组件静默添加列表数据
      console.log(data)
      this.tableData.push(data)
      this.dialogVisible = false
    },
    // 添加或修改
    update() {
      // if (this.id !== '') {  //修改
      //   this.dataForm['id'] = this.id
      //   this.$api.post('sign/update', this.dataForm, res => {
      //     console.log(res, 'wwww')
      //     if (res.code === 0) {
      //       console.log('修改')
      //       this.reload()
      //     }
      //   })
      // } else {   //添加
      let roleList = this.roleIds.join(',')
      let workDays = this.workDays.join(',')
      this.dataForm['roleIds'] = roleList
      this.dataForm['workDays'] = workDays
      this.$api.post('sign/save', this.dataForm, res => {
        console.log(res, 'wwww')
        if (res.code === 0) {
          console.log('添加成功')
          this.reload()
        }
      })
    }

    // }
  }
}
</script>

<style scoped>
.head-name-title {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  height: 57px;
  line-height: 57px;
  border-bottom: 2px solid #E8E8E8;
}

.table-title-name {
  font-size: 20px;
  font-weight: 400;
  color: #333333;
  margin: 20px 0 5px 36px;

}

.dialog-title {
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  margin-bottom: 30px;
}

.dialog-button {
  height: 60px;
  background: #009400;
  border-radius: 30px;
  width: 400px;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FEFEFE;
  margin-top: 30px;
}
</style>
