<template>
  <div>
    <div class="head-name-title" style="margin-left: 20px">农资品类管理</div>
    <div style="margin:20px 0 30px 20px ">
      <el-button type="primary" @click="addClassify" v-if="this.$getPermission('/pc/farmingCapitalType/save')">添加品类
      </el-button>
      <el-dialog
          title=""
          :visible.sync="dialogVisible"
          width="600px"
      >
        <div style="height: 284px;width: 400px;text-align: center;margin-left: 80px">
          <div class="dialog-title">{{ dialogName }}品类</div>
          <el-form label-width="100px">
            <el-form-item label="品类名称">
              <el-input v-model="dataForm.name"></el-input>
            </el-form-item>

            <el-form-item label="品类状态">
              <el-select v-model="dataForm.status" placeholder="请选择" style="width: 300px;">
                <el-option
                    v-for="item in selectList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>

          </el-form>
          <el-col>
            <el-button class="dialog-button" @click="update">提交</el-button>
          </el-col>
        </div>
      </el-dialog>

    </div>
    <div>
      <el-table
          :data="tableData"
          border
          style="width: 918px;margin-left: 36px;margin-bottom: 30px"
          :row-class-name="tableRowClassName">
        <el-table-column
            prop="id"
            label="序号"
        >
        </el-table-column>
        <el-table-column
            prop="name"
            label="品类">
        </el-table-column>
        <el-table-column
            prop="status"
            :formatter="changeStatus"
            label="状态">
        </el-table-column>
        <el-table-column
            label="操作">
          <template slot-scope="scope">
            <el-button
                size="mini"
                @click="handleEdit(scope.$index, scope.row)">编辑
            </el-button>
            <el-button
                size="mini"
                type="danger"
                @click="handleDelete(scope.$index, scope.row)">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          style="margin-bottom: 20px;margin-left: 30px"
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="pageIndex"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          :total="totalPage"
          layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  inject: [
    'reload'
  ],
  data() {
    return {
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataForm: {
        name: '',
        status: ''
      },
      id: '',
      dialogVisible: false,
      dialogName: '添加',
      tableData: [],
      selectList: [
        {
          name: '正常',
          id: 0
        }, {
          name: '禁用',
          id: 1
        }
      ]
    }
  },
  mounted() {
    this.getDataList()
    localStorage.setItem('selectIndex', '2')
  },
  methods: {
    changeStatus(row) {
      return row.status == '0' ? "正常" : row.status == '1' ? "禁用" : "";
    },
    addClassify() {
      this.dialogVisible = true
      this.dialogName = '添加'
      this.id = ''
      this.dataForm.name = ''
      this.dataForm.status = ''
    },
    getDataList() {
      this.$api.post('farmingCapitalType/getList', {
        "page": this.pageIndex,
        "size": this.pageSize,
      }, res => {
        console.log(res, '123')
        this.tableData = res.data.records
        this.totalPage = res.data.total
      });
    },
    tableRowClassName({rowIndex}) {
      if (rowIndex % 2 == 1) {
        return 'warning-row';
      } else if (rowIndex === 3) {
        return 'success-row';
      }
      return '';
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 添加或修改
    update() {
      if (this.id !== '') {  //修改
        this.dataForm['id'] = this.id
        this.$api.post('farmingCapitalType/update', this.dataForm, res => {
          console.log(res, 'wwww')
          if (res.code === 0) {
            console.log('修改')
            this.reload()
          }
        })
      } else {   //添加
        this.$api.post('farmingCapitalType/save', this.dataForm, res => {
          console.log(res, 'wwww')
          if (res.code === 0) {
            console.log('添加成功')
            this.reload()
          }
        })
      }

    },
    handleEdit(index, row) {  // 修改按钮
      const _self = this
      if (this.$getPermission('/pc/farmingCapitalType/update')) {
        _self.dialogName = '修改'
        _self.changeList = []
        console.log(index, row.id);
        let idx = _self.tableData.findIndex((item) => item.id === row.id)
        console.log(idx, 'idx')
        _self.changeList = _self.tableData[idx]
        _self.dataForm.name = _self.tableData[idx].name
        _self.dataForm.status = _self.tableData[idx].status
        _self.id = _self.tableData[idx].id
        _self.dialogVisible = true
        console.log(this.changeList, 'this.changeList')

      } else {
        this.$message({
          showClose: true,
          message: '暂无权限',
          type: 'warning'
        });
      }
    },
    handleDelete(index, row) {  //删除按钮
      console.log(index, row);
      if (this.$getPermission('/pc/farmingCapitalType/delById')) {
        this.$api.post('farmingCapitalType/delById', {id: row.id}, res => {
          console.log(res, '删除', this.tableData)
          let idx = this.tableData.findIndex((item) => item.id === row.id)
          console.log(idx)
          this.tableData.splice(idx, 1)
          this.$message({
            showClose: true,
            message: res.msg,
            type: 'success'
          });
        })

      } else {
        this.$message({
          showClose: true,
          message: '暂无权限',
          type: 'warning'
        });
      }
    },
    addtabList(data) {  //子组件静默添加列表数据
      console.log(data)
      this.tableData.push(data)
      this.dialogVisible = false
    }
  }
}
</script>

<style scoped>
.head-name-title {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  height: 57px;
  line-height: 57px;
  border-bottom: 2px solid #E8E8E8;
}

.table-title-name {
  font-size: 20px;
  font-weight: 400;
  color: #333333;
  margin: 20px 0 5px 36px;

}

.dialog-title {
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  margin-bottom: 30px;
}

.dialog-button {
  height: 60px;
  background: #009400;
  border-radius: 30px;
  width: 400px;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FEFEFE;
  margin-top: 30px;
}
</style>
