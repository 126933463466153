<template>
  <div class="base-box">
    <div style="height: 80px;line-height: 80px;margin-left: 20px;border-bottom: 1px solid #CCCCCC">基地信息
    </div>
    <div style="width: 680px;margin-left: 40px;margin-top: 30px">

      <el-form ref="form" label-width="120px">
        <el-form-item label="基地名称">
          <el-input v-model="name"></el-input>
        </el-form-item>
        <el-form-item label="基地介绍">
          <el-input type="textarea" :rows="5" v-model="desc"></el-input>
        </el-form-item>
        <el-form-item label="基地规划图">
          <el-upload
              :action="url"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :file-list="parseImgList"
              :on-success="getImg"
              :on-remove="handleRemove">
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="">
          </el-dialog>
        </el-form-item>
        <el-form-item style="text-align: center;margin-top: 50px">
          <el-button class="update-name-form" type="primary" @click="onSubmit">保存</el-button>
        </el-form-item>
      </el-form>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogImageUrl: '',
      dialogVisible: false,
      parseImgList: [],
      name: '',
      desc: '',
      id: '',
      delivery: false,
      type: [],
      imgList: []
    }
  },
  mounted() {

    this.$api.post('base/getBase', {}, res => {
      console.log(res)
      this.name = res.data.name
      this.desc = res.data.desc
      this.id = res.data.id
      if (res.data.img2) {
        this.imgList = JSON.parse(res.data.img2)
        JSON.parse(res.data.img2).forEach((item, index) => {
          this.parseImgList.push({
            url: item,
            id: index
          })
        })
      }
      localStorage.setItem('basename', res.data.name)
    })
  },
  computed: {
    url() {
      return this.$api.host + '/pic/upload?token=' + localStorage.getItem('token')
    }
  },
  methods: {
    onSubmit() {
      let params = {
        desc: this.desc,
        name: this.name,
        id: this.id,
        img2: JSON.stringify(this.imgList)
      }
      if (this.$getPermission('/pc/base/save')) {
        this.$api.post('base/save', params, res => {
          console.log(res)
          this.$message({
            message: '修改成功',
            type: 'success',
            duration: 1500,
          })
        })

      } else {
        this.$message({
          showClose: true,
          message: '暂无权限',
          type: 'warning'
        });
      }
    },
    getImg(file) {
      this.imgList.push(file)
      this.parseImgList.push({
        url: file,
        id: this.parseImgList.length + 1
      })
      console.log(file, '返回值')
    },
    handleRemove(res) {
      if (res.id != '' || res.id == 0) {
        console.log('进来了111')
        let idx = this.parseImgList.findIndex((item) => item.id == res.id)
        this.parseImgList.splice(idx, 1)
        console.log(res.id)
        console.log(this.parseImgList)
        this.imgList = this.parseImgList
        let dataImgList = []
        this.parseImgList.forEach((item) => {
          dataImgList.push(item.url)
        })
        this.imgList = dataImgList
      } else if (res.response) {
        console.log('进来了222')
        let rea = res.response.msg
        let idx = this.imgList.findIndex((itm) => itm == rea)
        this.imgList.splice(idx, 1)
        console.log(this.imgList, '少时诵诗书所所所所所所所')
      }
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    }
  }
}
</script>

<style scoped>
.base-box {
  min-height: 660px;
  margin-top: 20px;
  background: #FFFFFF;
}

.update-name-form {
  width: 400px;
  height: 60px;
  background: #009400;
  border-radius: 30px;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;

}
</style>
